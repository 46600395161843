
/* global PDFLib */

//------------------------------------------------------------------------------

var mimeTypes = ['image/png', 'image/jpeg', 'image/bmp', 'application/pdf'];
var pdfjsLib = window['pdfjs-dist/build/pdf'];
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.min.js';

var pdfCompressor = {
    file: null,
    cb: null,
    reader: new FileReader(),
    init: function (file, cb) {
        this.file = file;
        this.cb = cb;
        if (isPDF(file)) {
            this.load();
        }
    },
    load: function () {
        readFile(this.file, (data) => {
            pdfjsLib.getDocument({data: data}).promise.then((pdf) => {
                PDFLib.PDFDocument.create().then((newPDFDoc) => {
                    const renderPagePromises = [];
                    let promises = [];
                    const scale = 1;
                    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                        promises.push(pdf.getPage(pageNum).then((page) => {
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d');
                            const newPage = newPDFDoc.addPage([page.view[2] * scale, page.view[3] * scale]);
                            canvas.width = newPage.getWidth();
                            canvas.height = newPage.getHeight();
                            const viewport = page.getViewport({scale});
                            const renderContext = {
                                canvasContext: context,
                                viewport: viewport
                            };
                            return page.render(renderContext).promise.then(() => {
                                const imageData = canvas.toDataURL('image/jpeg');
                                return newPDFDoc.embedJpg(imageData).then((image) => {
                                    newPage.drawImage(image, {
                                        x: 0,
                                        y: 0,
                                        width: newPage.getWidth(),
                                        height: newPage.getHeight()
                                    });
                                });
                            });
                        }));
                    }
                    Promise.all(promises).then(() => {
                        newPDFDoc.save().then((compressedPDFBytes) => {
                            const blob = new Blob([compressedPDFBytes], {type: 'application/pdf'});
                            this.cb(blob);
                        });
                    });
                });
            });
        });
    }
};
//------------------------------------------------------------------------------

function isImage(file) {
    let i = mimeTypes.indexOf(file.type);
    return i > -1 && i < 3;
}

function isPDF(file) {
    return file.type === 'application/pdf';
}
function compressImg(file, ratio, cb) {
    if (file && isImage(file)) {
        readFile(file, (data) => {
            var img = new Image();
            img.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);
                canvas.toBlob(function (blob) {
                    cb(blob);
                }, 'image/jpeg', ratio);
            };
            img.src = data;
        });
    } else {
        cb(null, true);
        alert('Il faut sélectionner un fichier');
    }
}

function resetFileInput(field) {
    document.getElementById('doc-' + field).value = '';
}

function readFile(file, cb) {
    var reader = new FileReader();
    reader.onload = function (e) {
        cb(e.target.result);
    };
    if (isImage(file)) {
        reader.readAsDataURL(file);
    } else {
        reader.readAsArrayBuffer(file);
    }
}

angular.module(config.app.name).component('documents', {
    templateUrl: 'components/procedure/components/documents.component.html',
    bindings: {
        procedureId: '<',
        procedureType: '<',
        canGoBack: '<',
        getData: '&',
        setData: '&'
    },
    controller: function ($element, $scope, $timeout, toast, $_procedures, $_documents, sessionStorageService) {
        var self = this;

        self.view = {
            title: 'Documents',
            icon: 'folder'
        };

        self.documentsUploadURL = null;
        self.compressFiles = true;

        self.$onInit = function () {
            $_procedures.getDocuments(self.procedureId, self.procedureType).then((documents) => {
                self._documents = documents.DocumentsFields.$and;
                sessionStorageService.set('documents', self._documents);
                self.types = $_documents.prepareDocuments(self._documents);
                $timeout(() => $scope.$apply());
            });
        };

        self.selectSubType = function (subType) {
            self.types = $_documents.selectSubType(self.types, JSON.parse(subType));
            $timeout(() => $scope.$apply());
        };

        self.getSubTypes = function (document) {
            return $_documents.getSubDocuments(self.types, document);
        };

        self.setCurrentDoc = function (doc) {
            self.currentDoc = doc;
            $timeout(() => $scope.$apply());
        };

        function uploadDocument(document) {
            let formData = new FormData();
            formData.append(self.currentDoc.field, document);
            $_procedures.uploadDocument(self.procedureId, formData, self.currentDoc.type).then(() => {
                self.currentDoc.uploadProgress = false;
                self.currentDoc.uploaded = true;
                $_documents.updateParentDocuments(self.types);
                toast.show('Le document a bien été téléversé.', 'success');
                resetFileInput(self.currentDoc.field);
                $timeout(() => $scope.$apply());
            }).catch((err) => {
                self.currentDoc.uploadProgress = false;
                resetFileInput(self.currentDoc.field);
                console.error('upload document error :');
                console.log(err);
                toast.show(err, 'error', true);
            });
        }

        self.onFileSelect = function (selectedFile) {
            self.currentDoc.uploadProgress = true;
            $timeout(() => $scope.$apply());
            if (self.compressFiles !== true) {
                return uploadDocument(selectedFile);
            } else {
                if (isImage(selectedFile)) {
                    return compressImg(selectedFile, 0.7, function (data, err) {
                        if (err) {
                            self.currentDoc.uploadProgress = false;
                        } else {
                            uploadDocument(data);
                        }
                    });
                } else if (isPDF(selectedFile)) {
                    return pdfCompressor.init(selectedFile, function (data, err) {
                        if (err) {
                            self.currentDoc.uploadProgress = false;
                        } else {
                            uploadDocument(data);
                        }
                    });
                }
            }
        };

        self.getDocumentsUploadLink = function () {
            $_procedures.getDocumentsUploadLink(self.procedureId).then((res) => {
                self.documentsUploadURL = res.documentsUploadLink;
            }).catch((err) => {
                console.error('get documents upload link error :');
                console.log(err);
                toast.show(err, 'error', true);
            });
        };

        self.sendDocumentsUploadLink = function () {
            $_procedures.getDocumentsUploadLink(self.procedureId, self.phone).then((res) => {
                if (res) {
                    toast.show('Le lien de téléversement de documents a bien été envoyé par SMS au ' + self.phone, 'success');
                }
            }).catch((err) => {
                console.log('>>> err :', err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        self.next = function () {
            self.setData({
                type: 'documents'
            });
        };

        self.back = function () {
            $element[0].dispatchEvent(new CustomEvent('navigation', {
                detail: 'back'
            }));
        };
    }
});